import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import './assets/main.scss'
import { register } from 'swiper/element/bundle';

register()

import mitt from "mitt";
const emitter = mitt();
const app = createApp(App).use(router)
app.config.globalProperties.emitter = emitter;
app.mount("#app");

