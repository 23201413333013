import { createRouter, createWebHistory } from 'vue-router'


const routes = [
  {
    path: '/home',
    name: 'Home',
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue'),
    meta: {requireLogin: true},
    children: [
      {
        path: '/dashboard',
        name: 'Home',
        component: () => import(/* webpackChunkName: "dashboard" */ '../views/Intranet/Dashboard.vue')
      },
      {
        path: '/article',
        name: 'Article',
        component: () => import(/* webpackChunkName: "news-single" */ '../views/Intranet/News-Single.vue')
      },
      {
        path: '/event',
        name: 'Event',
        component: () => import(/* webpackChunkName: "event-single" */ '../views/Intranet/Event-Single.vue')
      },
      {
        path: '/adverts',
        name: 'Adverts',
        component: () => import(/* webpackChunkName: "event-single" */ '../views/Intranet/Adverts.vue')
      },
      {
        path: '/new-file-upload',
        name: 'New-File-Upload',
        component: () => import(/* webpackChunkName: "new-file-upload" */ '../views/Intranet/New-File-Upload.vue')
      },
      {
        path: '/latest-news',
        name: 'Latest News',
        component: () => import(/* webpackChunkName: "latest-news" */ '../views/Intranet/Latest-News.vue'),

      },
      {
        path: '/connect-with-it',
        name: 'Connect With IT',
        component: () => import(/* webpackChunkName: "connect-with-it" */ '../views/Intranet/Connect-With-IT.vue')
      },
      {
        path: '/company-events',
        name: 'Events',
        component: () => import(/* webpackChunkName: "company-events" */ '../views/Intranet/Company-Events.vue')
      },
      {
        path: '/user-create',
        name: 'Create User',
        component: () => import(/* webpackChunkName: "create-user" */ '../views/Intranet/User-Create.vue')
      },
      {
        path: '/user-update',
        name: 'Update User',
        component: () => import(/* webpackChunkName: "update-user" */ '../views/Intranet/User-Update.vue')
      },
      {
        path: '/user-dash',
        name: 'User Dashboard',
        component: () => import(/* webpackChunkName: "dash-user" */ '../views/Intranet/User-Dash.vue')
      },
      {
        path: '/user-profile',
        name: 'User Profile',
        component: () => import(/* webpackChunkName: "profile-user" */ '../views/Intranet/User-Profile.vue')
      },
      {
        path: '/company-sales',
        name: 'Company Sales',
        component: () => import(/* webpackChunkName: "company-sales" */ '../views/Intranet/Company-Sales.vue')
      },
      {
        path: '/expense-claims',
        name: 'Expense Claims',
        component: () => import(/* webpackChunkName: "expense-claims" */ '../views/Intranet/Expense-Claims.vue')
      },
      {
        path: '/expense-single',
        name: 'Expense Single',
        component: () => import(/* webpackChunkName: "expense-claims" */ '../views/Intranet/Expense-Single.vue')
      },
      {
        path: '/order-requisitions',
        name: 'Order Requisitions',
        component: () => import(/* webpackChunkName: "Order-Requisitions" */ '../views/Intranet/Order-Requisitions.vue')
      },
      {
        path: '/order-single',
        name: 'Order Single',
        component: () => import(/* webpackChunkName: "Order-Single" */ '../views/Intranet/Order-Single.vue')
      },
      {
        path: '/file-manager',
        name: 'File Manager',
        component: () => import(/* webpackChunkName: "file-manager" */ '../views/Intranet/File-Manager.vue')
      },

      {
        path: '/employee-birthdays',
        name: 'Birthdays',
        component: () => import(/* webpackChunkName: "employee-birthdays" */ '../views/Intranet/Employee-Birthdays.vue')
      },
      {
        path: '/useful-links',
        name: 'Useful Links',
        component: () => import(/* webpackChunkName: "useful-links" */ '../views/Intranet/Useful-Links.vue')
      },
      {
        path: '/browse-market-materials',
        name: 'Marketing Materials',
        component: () => import(/* webpackChunkName: "browse-market-materials" */ '../views/Intranet/Browse-Market-Materials.vue')
      },
      {
        path: '/browse-dealer-letters',
        name: 'Dealer Letters',
        component: () => import(/* webpackChunkName: "browse-dealer-letters" */ '../views/Intranet/Browse-Dealer-Letters.vue')
      },
      {
        path: '/upload-dealer-letter',
        name: 'Upload Dealer Letter',
        component: () => import(/* webpackChunkName: "upload-dealer-letters" */ '../views/Intranet/Upload-Dealer-Letter.vue')
      },
      {
        path: '/upload-purchase-order',
        name: 'Upload Purchase Order',
        component: () => import(/* webpackChunkName: "upload-purchase-order" */ '../views/Intranet/Upload-Purchase-Order.vue')
      },
      {
        path: '/upload-purchase-invoice',
        name: 'Upload Purchase Invoice',
        component: () => import(/* webpackChunkName: "upload-purchase-invoice" */ '../views/Intranet/Upload-Purchase-Invoice.vue')
      },
      {
        path: '/browse-icare',
        name: 'Browse Icare',
        component: () => import(/* webpackChunkName: "browse-dealer-letters" */ '../views/Intranet/Browse-Icare.vue')
      },
      {
        path: '/upload-icare',
        name: 'Upload Icare',
        component: () => import(/* webpackChunkName: "upload-dealer-letters" */ '../views/Intranet/Upload-Icare.vue')
      },
      {
        path: '/browse-hyundai-values',
        name: 'Browse Hyundai Values',
        component: () => import(/* webpackChunkName: "browse-dealer-letters" */ '../views/Intranet/Browse-Hyundai-Values.vue')
      },
      {
        path: '/browse-parts',
        name: 'Browse Genuine Parts',
        component: () => import(/* webpackChunkName: "browse-dealer-letters" */ '../views/Intranet/Browse-Parts.vue')
      },
      {
        path: '/browse-services',
        name: 'Browse Services',
        component: () => import(/* webpackChunkName: "browse-dealer-letters" */ '../views/Intranet/Browse-Services.vue')
      },
      {
        path: '/browse-we-buy',
        name: 'Browse We Buy',
        component: () => import(/* webpackChunkName: "browse-dealer-letters" */ '../views/Intranet/Browse-We-Buy.vue')
      },
      {
        path: '/browse-promise',
        name: 'Browse H Promise',
        component: () => import(/* webpackChunkName: "browse-dealer-letters" */ '../views/Intranet/Browse-Promise.vue')
      },
      {
        path: '/upload-hyundai-values',
        name: 'Upload Hyundai Values',
        component: () => import(/* webpackChunkName: "upload-dealer-letters" */ '../views/Intranet/Upload-Hyundai-Values.vue')
      },
      {
        path: '/browse-price-updates',
        name: 'Price Updates',
        component: () => import(/* webpackChunkName: "browse-price-updates" */ '../views/Intranet/Browse-Price-Updates.vue')
      },
      {
        path: '/browse-banners',
        name: 'Browse Banners',
        component: () => import(/* webpackChunkName: "browse-banners" */ '../views/Intranet/Browse-Banners.vue')
      },
      {
        path: '/browse-brochures',
        name: 'Browse Brochures',
        component: () => import(/* webpackChunkName: "browse-brochures" */ '../views/Intranet/Browse-Brochures.vue')
      },
      {
        path: '/browse-launchpad',
        name: 'Browse Launchpad',
        component: () => import(/* webpackChunkName: "browse-launchpad" */ '../views/Intranet/Browse-Launchpad.vue')
      },
      {
        path: '/browse-spec',
        name: 'Browse Spec',
        component: () => import(/* webpackChunkName: "browse-price-updates" */ '../views/Intranet/Browse-Spec.vue')
      },
      {
        path: '/upload-price-updates',
        name: 'Upload Price Updates',
        component: () => import(/* webpackChunkName: "browse-price-updates" */ '../views/Intranet/Upload-Price-Update.vue')
      },
      {
        path: '/model-documents',
        name: 'Model Documents',
        component: () => import(/* webpackChunkName: "model-documents" */ '../views/Intranet/Model-Documents.vue')
      },
      {
        path: '/add-asset',
        name: 'Add-Asset',
        component: () => import(/* webpackChunkName: "add-asset" */ '../views/Intranet/Add-Asset.vue')
      },
    ]
  },
  {
    path: '/',
    name: 'Count-Down',
    component: () => import(/* webpackChunkName: "count-down" */ '../views/Count-Down.vue')
  },
  {
    path: '/login-screen',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login-screen" */ '../views/Login-Screen.vue')
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import(/* webpackChunkName: "register-screen" */ '../views/Register-Screen.vue')
  },
  {
    path: '/forgot-password',
    name: 'Forgot-Password',
    component: () => import(/* webpackChunkName: "register-screen" */ '../views/Forgot-Password.vue')
  },
  {
    path: '/reset-password',
    name: 'Reset-Password',
    component: () => import(/* webpackChunkName: "reset-password" */ '../views/Reset-Password.vue')
  },
  {
    path: '/two-factor',
    name: 'Two Factor',
    component: () => import(/* webpackChunkName: "two-factor" */ '../views/Two-Factor.vue')
  },
  {
    path: "/:pathMatch(.*)*",
    name: 'Not-Found',
    component: () => import(/* webpackChunkName: "not-found" */ '../views/Count-Down.vue')
  },


]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to,from,next) => {
  if(to.meta.requireLogin){

    if(localStorage.getItem("userDetails") === null){
      next({
        name: "Login"
      })
    } else {
      next()
    }
  }else{
    next()
  }
})



export default router
